export default {
  HOME: '/',
  LOGIN: '/users/login',
  PROFILE: '/profile',
  REGISTER: '/users/register',
  FORGOT_PASSWORD: '/users/login/forgot-password',
  REGISTER_LINK: '/users/register_link',
  MIGRATION_TO_RM: '/users/migrate_to_rm',
  SMART_REMOTE: '/smart-remote',
  SUPPORT: '/support',
  CUSTOMER_API_V1: '/docs/customer-api-v1',
  CUSTOMER_API_V2: '/docs/customer-api-v2',
  CUSTOMER_API_V3: '/docs/customer-api-v3',
  CUSTOMER_API_V4: '/docs/customer-api-v4',
  PREMIUM_CUSTOMER_API_V3: '/docs/premium-customer-api-v3',
  PREMIUM_CUSTOMER_API_V4: '/docs/premium-customer-api-v4',
  GATEWAY_API: '/docs/gateway-api',
  RESERVED_PARKING_LOTS: '/reservations/current',
  TERMS_OF_SERVICE: '/documents/terms-of-service-book-n-park.pdf',
  PRIVACY_POLICY: '/documents/privacy-policy.pdf',
  RESERVATIONS_REMOTE: '/reservations/remote',
  CODES: '/codes',
  ADD_CODE: '/codes/add',
  ADD_TUD_CODE: '/codes/add-tud-code',
  MY_RESERVATIONS: '/reservations/history',
  CREATE_RESERVATION: '/reservations/add',
  ANNOUNCEMENTS: '/announcements',
  TENANTS: '/tenants',
  LOCATIONS: '/locations',
  GATEWAYS: '/gateways',
  PRODUCTS: '/products',
  LOGS: '/logs',
  ROLES: '/roles',
  RESERVATIONS: '/reservations/all',
  EVENTS: '/events',
  ADD_EVENT: '/events/add',
  EDIT_EVENT: '/events/edit',
  USERS: '/users',
  PASSWORD_RESET: '/users/password_reset',
  GATEWAY_API_LOGS: '/gateway-api/logs',
  GATEWAY_API_ADD_GATEWAY: '/gateway-api/deploy',
  GATEWAY_API_GATEWAYS: '/gateway-api/gateways',
  GATEWAY_API_GATEWAY: '/gateway-api/gateway',
  DB_ISSUES: '/issues',
  AUDI_OICD_LOGIN_CALLBACK: '/auth',
  AUDI_OICD_REGISTER_CALLBACK: '/register/auth',
  NOT_FOUND: '*',

};
