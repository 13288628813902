/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import BaseModule from '../module';
import { State, RootState } from '../types';

interface OidcRegisterState extends State {
  sub: string;
}

class OidcRegisterModule extends BaseModule<OidcRegisterState> {
  protected initialState(): OidcRegisterState {
    return {
      sub: '',
    }
  }

  protected buildGettersTree(): GetterTree<OidcRegisterState, RootState> {
    return {
      sub: (state) => state.sub,
      subSet: (state) => state.sub.length > 0,
    }
  }

  protected buildMutationsTree(): MutationTree<OidcRegisterState> {
    return {
      changeSub(state, newSub) {
        state.sub = newSub
      },
    }
  }

  protected buildActionsTree(): ActionTree<OidcRegisterState, RootState> {
    return {
      setSubTo(context, sub: string) {
        context.commit('changeSub', sub);
      },
    }
  }
}

export default new OidcRegisterModule(true);
