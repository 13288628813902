/* eslint-disable no-useless-constructor */
import User from '@/model/user';
import Zone from '@/model/zone';
import Response from '@/network/response';
import RestService from '../restService';
import CRUDRepository from './crudRepository';

export default class UserRepository extends CRUDRepository<User> {
  private static readonly LOGIN_URL = '/users/login';

  private static readonly ANNOUNCEMENTS_URL = '/announcements/currentlyActive';

  private static readonly PRODUCTS_URL = '/users/me/products';

  private static readonly LOGOUT_URL = '/users/logout';

  private static readonly RESET_PASSWORD_URL = '/users/password_reset_request';

  private static readonly PROFILE_URL = '/users/profile';

  private static readonly BASE_URL = '/users';

  private static readonly ADD_URL = '/users/add';

  private static readonly EDIT_URL = '/users/edit';

  private static readonly DELETE_URL = '/users/delete';

  private static readonly SEARCH_URL = '/users/filter_ajax';

  private static readonly INVITE_RM_URL = '/users/rm_invitation';

  private static readonly REMOVE_RM_URL = '/users/remove_rm';

  private static readonly CHECK_TOKEN_URL = '/users/getUserEmailWithRegisterToken'

  private static readonly CHECK_INVITE_TOKEN_URL = '/users/getUserEmailFromInvitationToken'

  private static readonly ACCOUNT_BALANCE_URL = '/users/getAccountBalanceForCurrentUser'

  private static readonly REGISTER_URL = '/users/register';

  private config = {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  constructor(service: RestService) {
    super(
      service,
      UserRepository.BASE_URL,
      UserRepository.ADD_URL,
      UserRepository.EDIT_URL,
      UserRepository.DELETE_URL,
    );
  }

  async login(credentials: { username: string; password: string }) {
    const data = {
      data: {
        type: 'users',
        attributes: credentials,
      },
    }

    const loginResponse = await this.service.post(
      `${UserRepository.LOGIN_URL}?include=roles,tenants,zones`,
      data,
      this.config,
    );
    const announcementsResponse = await this.service.get(UserRepository.ANNOUNCEMENTS_URL);
    const productsResponse = await this.service.get(
      UserRepository.PRODUCTS_URL,
      this.config,
    );

    const userObject: User = UserRepository.parseLoginResponse(
      loginResponse,
      announcementsResponse,
      productsResponse,
    );

    return userObject;
  }

  static parseLoginResponse(
    loginResponse: Response<any>,
    announcementsResponse: Response<any>,
    productsResponse: Response<any>,
  ): any {
    const includes: Array<any> = loginResponse.data.included;

    // *** sessionTenant ***
    const { sessionTenant } = loginResponse.data.meta ?? { sessionTenant: null };

    // *** announcements ***
    const announcements = announcementsResponse.data;

    // *** features ***
    const productsList: Array<any> = productsResponse.data.data;
    const features = productsList.map(
      (el) => el.attributes.prefix,
    );

    // *** zones ***
    const userZonesArray: Array<any> = loginResponse.data.data.relationships.zones.data;
    const zoneIds: Array<number> = userZonesArray.map((el) => el.id);
    const zonesIncludes: Array<any> = includes.filter(
      (el) => el.type === 'zones' && zoneIds.includes(el.id),
    );
    const zones: Array<any> = zonesIncludes.map((el: any) => (
      {
        ...(el.attributes),
        id: parseInt(el.id, 10),
      }
    ));

    // *** tenant ***
    const userTenantsArray: Array<any> = loginResponse.data.data.relationships.tenants.data;
    const tenantIds: Array<number> = userTenantsArray.map((el) => el.id);
    const tenantIncludes: Array<any> = includes.filter(
      (el) => el.type === 'tenants' && tenantIds.includes(el.id),
    );
    const tenant: Array<any> = tenantIncludes.map((el) => (
      {
        ...(el.attributes),
        id: parseInt(el.id, 10),
      }
    ))
      .at(0) ?? {};

    // *** roles ***
    const userRolesArray: Array<any> = loginResponse.data.data.relationships.roles.data;
    const userRolesIds: Array<number> = userRolesArray.map((el) => el.id);
    const rolesIncludes: Array<any> = includes.filter(
      (el: any) => el.type === 'roles' && userRolesIds.includes(el.id),
    );
    const roles: Array<any> = rolesIncludes.map((el) => (
      {
        ...(el.attributes),
        id: parseInt(el.id, 10),
      }
    ))
      .at(0) ?? {};

    // *** user ***
    const userAttributes = loginResponse.data.data.attributes;
    const userObject = {
      id: loginResponse.data.data.id,
      username: userAttributes.username,
      accountBalance: userAttributes.accountBalance,
      created: userAttributes.created,
      modified: userAttributes.modified,
      email: userAttributes.email,
      mobileNumber: userAttributes.mobileNumber,
      landlineNumber: userAttributes.landlineNumber,
      firstname: userAttributes.firstname,
      lastname: userAttributes.lastname,
      title: userAttributes.title,
      gender: userAttributes.gender,
      street: userAttributes.street,
      ZIP: userAttributes.zip,
      AGB: userAttributes.agb,
      type: userAttributes.type,
      role: roles,
      zones,
      features,
      announcements,
      fullName: userAttributes.fullName,
      tenant,
      sessionTenant,
      rmContactEmail: userAttributes.rmContactEmail,
      rmContactPhoneNumber: userAttributes.rmContactPhoneNumber,
      language: userAttributes.language.toLowerCase(),
      licencePlate: userAttributes.licencePlate,
      city: userAttributes.city,
    }

    return userObject;
  }

  async logout() {
    await this.service.post(UserRepository.LOGOUT_URL, {}, this.config);
  }

  resetPassword(email: string) {
    return this.service.post(UserRepository.RESET_PASSWORD_URL, email);
  }

  async getProfile() {
    const user = await this.service.get<User>(UserRepository.PROFILE_URL);
    return user.data;
  }

  async updateProfile(user: User) {
    await this.service.patch(UserRepository.PROFILE_URL, user);
  }

  async search(query: string) {
    const response = await this.service.get<User>(`${UserRepository.SEARCH_URL}?search=${query}`);
    return response.data;
  }

  async inviteToBecomeReservationManager(data: { email: string; zones: Zone[] }) {
    await this.service.post(UserRepository.INVITE_RM_URL, data);
  }

  async removeReservationManagerRights(user: User) {
    const response = await this.service.post<User[]>(
      UserRepository.REMOVE_RM_URL,
      { userId: user.id },
    );
    return response.data;
  }

  async checkInvitationToken(token: string) {
    const response = await this.service.get<string>(`${UserRepository.CHECK_INVITE_TOKEN_URL}/${token}`)
    return response.data;
  }

  async getAccountBalance() {
    const response = await this.service.get<string>(`${UserRepository.ACCOUNT_BALANCE_URL}`)
    return response.data;
  }

  async register(user: { email: string; password: string; AGB: boolean; language: string; }) {
    const response = await this.service.post<any>(UserRepository.REGISTER_URL, user);
    return response.data;
  }
}
