import Vue from 'vue';
// import Element from 'element-ui';
import VueMeta from 'vue-meta';

// Problem witht that is that it wont change to english
// import locale from 'element-ui/lib/locale/lang/de';
import axiosRestService from '@/network/axiosRestService';
import * as VueGoogleMaps from 'vue2-google-maps';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { OidcClient, UserManager } from 'oidc-client-ts';
import * as Oidc from 'oidc-client-ts';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import i18n from './i18n';
import store from './store';
import vuetify from './plugins/vuetify';

// Globals
Vue.prototype.$audiMinPrebookTimeMins = 30;
Vue.prototype.$audiMinReservationDurationMins = 45;

Vue.config.productionTip = false;
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

async function loadMaps() {
  await store.restored;
  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      libraries: 'places',
      region: 'DE',
      language: store.getters.language,
    },
  });
}

loadMaps();

Vue.directive('scroll', {
  inserted(el, binding) {
    function f(evt: Event) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    }
    window.addEventListener('scroll', f);
  },
});

if (
  process.env.NODE_ENV === 'production'
  && window.location.hostname.endsWith('staging.booknpark.de')
) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['*.booknpark.de', '*.book-n-park.de'],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Oidc.Log.setLogger(console);
console.log('in main.ts')

const url = `${window.location.protocol}//${window.location.host}/auth`
console.log('Callback URL')
console.log(url)
if (!Vue.prototype.$audiOidcUserManager) {
  console.log('Initializing new user manager')
  Vue.prototype.$audiOidcUserManager = new UserManager({
    authority: 'https://identity-sandbox.vwgroup.io',
    client_id: 'bc92e7ef-d557-4af1-8f64-3ddc534c28c7@apps_vw-dilab_com',
    redirect_uri: url,
    response_type: 'code',
    scope: 'openid',
    client_secret: '7e0a02d324a64ad88e1bf42344450b6abe89ef531d5c4a79356a7a5de6ed4216',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    response_mode: null,
  })
}
Vue.prototype.$audiOidcUserManager.getUser().then((user: any) => {
  if (user.id_token) {
    axiosRestService.setBearerToken(user.id_token)
  }
})
async function setLanguage() {
  await store.restored;
  i18n.locale = store.getters.language;

  // We don't want to update the database on the login page, since the user isn't logged in yet
  store.dispatch('translateToWithoutDB', store.getters.language);
}

async function clearOldNotifications() {
  await store.restored;
  store.dispatch('notifications/removeOldNotifications', store.getters.notifications);
}

setLanguage();
clearOldNotifications();
